<template>
  <VModalWidget :submit="submit" :dismiss="dismiss" :submitting="submitting" :submit-title="$t('labels.save')">
    <template v-slot:title>{{ $t('labels.blocker') }}</template>
    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <v-row>
          <v-col cols="12">
            <BlockerField v-model="blocker" :errors="blockerErrors" />
          </v-col>
          <v-col cols="12">
            <BlockerTypeField v-model="form.blocker_type" :errors="blockerTypeErrors" />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment-timezone'
import pick from 'lodash/pick'
import { Modal } from '@argon/app/mixins'
import { VModalWidget, BaseForm, FormErrors } from '@argon/app/components'
import { NAMESPACE } from '@argon/evo/entities/store'
import { BlockerField, BlockerTypeField } from '../../blockers/components'
import { NAMESPACE as BLOCKER_NAMESPACE } from '../../blockers/store'

export default {
  name: 'DealBlockerModal',
  extends: BaseForm,
  mixins: [Modal],
  components: {
    VModalWidget,
    FormErrors,
    BlockerField,
    BlockerTypeField
  },
  props: {
    entityId: { type: [String, Number], required: true }
  },
  validations: {
    form: {
      blocker: {},
      blocker_type: {}
    }
  },
  data: () => ({
    form: {
      blocker_type: null,
      blocker: null,
      run_on: null
    },
    submitting: false
  }),
  computed: {
    blockerErrors() {
      const errors = []
      this.getServerErrors('blocker', errors)
      if (!this.$v.form.blocker.$dirty) return errors
      return errors
    },
    blockerTypeErrors() {
      const errors = []
      this.getServerErrors('blocker_type', errors)
      if (!this.$v.form.blocker_type.$dirty) return errors
      !this.$v.form.blocker_type.required && errors.push(this.$t('required'))
      return errors
    },
    blocker: {
      get() {
        return this.form.blocker
      },
      set(v) {
        this.form.blocker = v
        this.form.run_on = moment(v.run_on).toISOString()
      }
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['patchEntity']),
    ...mapActions(BLOCKER_NAMESPACE, ['createBlocker', 'getBlocker']),
    setBlockerType(v) {
      this.form.blocker_type = v
    },
    setBlocker(v) {
      this.blocker = v
      this.form.blocker = v.id
      this.form.run_on = moment(v.run_on).toISOString()
    },
    submit() {
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.submitting = true
      let payload = {
        dealId: this.entityId,
        data: pick(this.form, ['blocker', 'run_on', 'blocker_type'])
      }
      this.createBlocker(payload)
        .then(() => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>
